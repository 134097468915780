<template>
  <div class="sm:w-64">

    <div v-if="isLoading"
         class="flex items-center justify-center h-full w-full">
      <!--<div class="fulfilling-square-spinner">
        <div class="spinner-inner"></div>
      </div>-->
      <div class="loading">
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
        <div class="dot"></div>
      </div>
    </div>
    <template v-else>
      <div v-if="epreuves.length == 0" class="border rounded-lg w-full text-center flex justify-center items-center">
        <el-result
            icon="info"
            title="Aucun type"
            subTitle="Il y a aucun type d'épreuve "
        >
        </el-result>
      </div>
      <template v-else>

        <div class="mb-2">
          <p class="font-semibold text-sm text-gray-800 text-left ml-2">Type d'épreuves</p>
        </div>
        <div class="rounded-lg">
          <!-- <el-scrollbar height="413px" class="w-full border rounded-md bg-white"> -->
          <el-menu
              class="el-menu-vertical-demo w-full border rounded-lg text-center bg-white"
              @select="handleSelect"
              @open="handleOpen"
              @close="handleClose"
              :collapse="isCollapse"
          >
            <el-scrollbar height="413px" class="w-full rounded-lg bg-white">
              <el-menu-item v-for="type in epreuves" :key="type.id" v-bind:index="type.id">
                <template #title>{{ type.title }}</template>
              </el-menu-item>
            </el-scrollbar>
          </el-menu>
          <!-- </el-scrollbar> -->
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import axiosIns from '../../../../../plugins/axios';

export default {
  data() {
    return {
      isLoading: false,
      epreuves: []

    }
  },
  methods: {
    getEpreuves() {
      this.isLoading = true;
      axiosIns.get("/epreuves/types")
          .then(({data}) => {
            this.epreuves = data;
            this.isLoading = false;
          })
          .catch(() => {
            this.errorm();
            this.isLoading = false;
          });
    },
    handleSelect(key) {
      this.$emit("selectType", key);
    }
  },
  computed: {
    userToken() {
      return this.$store.getters.get_token;
    }
  },
  mounted() {
    this.getEpreuves();
  }
}
</script>